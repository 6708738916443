@charset "UTF-8";
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}

p {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  /*font-weight: bold;*/
  font-size: small;
}

.underline {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

.pointer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

.pointer-underline {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

.pointer:hover {
  cursor: pointer;
}

.pointer-underline:hover {
  cursor: pointer;
}

.text-green {
  color: #06667E;
}

.background-green {
  background-color: #06667E;
}

body {
  margin: 0;
  padding: 0;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

p, a, li, strong, ul, td, textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75vw;
  color: #243254;
}

span {
  font-size: 0.75vw;
  font-family: "Montserrat", sans-serif;
  color: #243254;
}

h1, h2, h3, h4, h5, h6, th {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #243254;
  letter-spacing: -1px;
}

h3 {
  font-size: 1vw;
  font-weight: 400;
}

h4 {
  font-size: 1.03vw;
}

h2 {
  font-size: 20px;
}

h1 {
  font-size: 1.4vw;
}

h5, th {
  font-size: 15px;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: black;
}

.select-action__button {
  font-size: 45px !important;
  line-height: 0px;
  top: -6px;
  left: 6px;
  height: 0px;
  position: relative;
  letter-spacing: -3px;
}

.select-action {
  width: 41px;
  height: 14px;
  border-radius: 8px;
  color: grey;
}

.select-action:hover {
  color: black;
}

/*
.mobile{
}

.desktop{
}
*/
.section-width-90 {
  width: 90%;
  margin: 0 auto;
}

.section-width-100 {
  width: 100%;
}

.section-width-75 {
  width: 75%;
  margin: 0 auto;
}

.section-width-55 {
  width: 55%;
  margin: 0 auto;
}

.section-color {
  background: #223341;
}

.margins-section-accordion2__element {
  width: 80%;
  margin-left: 15%;
  padding-top: 4vw;
  padding-bottom: 4vw;
}

.dop-interval__mobile {
  display: none;
}

.dop-interval__deckstop {
  display: block;
}

.packet-mobile {
  display: none;
}

.counter {
  align-self: flex-start;
  height: 100%;
  display: flex;
  align-items: flex-end;
  gap: 0.5vw;
}

.counter_item {
  border-radius: 0.3vw;
}

.counter_item:hover {
  cursor: pointer;
}

.block-progress {
  display: flex;
  justify-content: center;
  margin-top: 3vw;
  align-items: center;
}

.active {
  width: 2.7vw;
  height: 0.5vw;
  transition: all 0.2s ease-in-out; /* Плавный переход всех изменений */
  transition: width 0.2s ease-in-out;
  background-color: #ED3E3E;
  margin-bottom: -0.1vw;
}

.inactive {
  width: 1.2vw;
  height: 0.3vw;
  transition: width 0.2s ease-in-out;
  background-color: #ccc;
}

@media (max-width: 1281px) {
  .active {
    width: 40px;
    height: 7px;
    margin-bottom: -1px;
  }
  .inactive {
    width: 20px;
    height: 4px;
  }
  .block-progress {
    margin-top: 30px;
  }
  .counter_item {
    border-radius: 4px;
  }
  .counter {
    gap: 7px;
  }
  p {
    font-size: 12px;
  }
  h5, a {
    font-size: 14px;
  }
  h3 {
    font-size: 16px;
  }
  .dop-interval__mobile {
    display: block;
  }
  .section-width-75 {
    width: 90%;
  }
  .margins-section-accordion2__element {
    width: 90%;
    margin-left: 5%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .dop-interval__deckstop {
    display: none;
  }
}
@media (max-width: 1024px) {
  span {
    font-size: 10px;
  }
  .section-width-55 {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .packet-decktop {
    display: none;
  }
  .packet-mobile {
    display: block !important;
  }
}
@media (min-width: 680px) {
  .mobile {
    display: none !important;
  }
}
@media (max-width: 680px) {
  .desktop {
    display: none !important;
  }
}
.points {
  width: 100%;
}

.points:after {
  content: " ";
  display: block;
  overflow: hidden;
  margin-top: -6px;
  background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

.points-noactive {
  width: 100%;
}

.points-noactive:after {
  content: " ";
  display: block;
  overflow: hidden;
  margin-top: -6px;
  background-image: linear-gradient(to right, lightgray 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: #ED3E3E;
}